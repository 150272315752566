<template>
  <div>
    <div class="header-row">
      <filter-group-toggle/>
    </div>
    <div class="header-row">
      <!-- channel name filter-->
      <div class="select-option">
        <v-select
          v-model="adFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          placeholder="광고계정 검색"
          :options="adOptions"
        />
      </div>

      <!-- channel name filter-->
      <div class="select-option-small">
        <v-select
          v-model="channelFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          placeholder="채널명 검색"
          :options="channelOptions"
        />
      </div>

      <!-- Search Bar -->
      <search-bar
        placeholder="캠페인명 검색"
      />
      <!-- date filter-->
      <date-range-filter
        show-checkbox-options
        placeholder="집행기간"
      />
    </div>
  </div>
</template>
<script>

import { inject } from '@vue/composition-api'
import vSelect from 'vue-select'
import DateRangeFilter from '@/views/components/table/DateRangeFilter.vue'
import SearchBar from '@/views/components/table/SearchBar.vue'
import FilterGroupToggle from '@/views/components/table/FilterGroupToggle.vue'

export default {
  components: {
    vSelect,
    FilterGroupToggle,
    SearchBar,
    DateRangeFilter,
  },
  setup() {
    const showAccepted = inject('showAccepted')
    const reversedOption = inject('reversedOption')
    const channelOptions = inject('channelOptions')
    const channelFilter = inject('channelFilter')
    const adOptions = inject('adOptions')
    const adFilter = inject('adFilter')

    return {
      channelOptions,
      channelFilter,
      adOptions,
      adFilter,
      showAccepted,
      reversedOption,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~flatpickr/dist/flatpickr.css';
</style>
