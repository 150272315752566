<template>
  <!-- Table Container Card -->
  <div class="table-content">
    <b-table
      id="campaign-table"
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      show-empty
      no-local-sorting
      empty-text="일치하는 데이터가 없습니다"
    >
      <template v-slot:empty="scope">
        <div class="my-3 mx-2 text-center">
          {{ scope.emptyText }}
        </div>
      </template>

      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ minWidth: '120px' }"
        >
      </template>

      <template #cell(name)="data">
        <b-link
          v-if="data.item.isParent"
          style="text-decoration: underline"
          class="font-weight-bold text-nowrap"
          @click="moveTo(data.item)"
        >
          {{ data.value }}
        </b-link>
        <div v-else>
          -
        </div>
      </template>

      <!-- Column: Ad Account -->
      <template #cell(adAccount)="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <!-- Column: Channel -->
      <template #cell(channel)="data">
        <b-link
          v-if="data.item.hasChildren"
          class="text-nowrap"
          style="text-decoration: underline"
          @click="toggleExpand(data.item)"
        >
          {{ isShowing(data.item) ? '접어보기' : `펼쳐보기 (${data.item.numOfChildren})` }}
        </b-link>
        <div
          v-else
          class="text-nowrap"
        >
          {{ data.value }}
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-icon
          v-if="data.item.status==='ACTIVATED'"
          v-b-modal.modal-alert-pause
          style="cursor: pointer;"
          scale="1.5"
          :icon="resolveCampaignStatus(data.item.status).icon"
          :variant="resolveCampaignStatus(data.item.status).variant"
          @click="selectedItem = data.item"
        />
        <b-icon
          v-else-if="data.item.status==='PAUSED'"
          v-b-modal.modal-alert-resume
          style="cursor: pointer"
          scale="1.5"
          :icon="resolveCampaignStatus(data.item.status).icon"
          :variant="resolveCampaignStatus(data.item.status).variant"
          @click="selectedItem = data.item"
        />
        <div
          v-else
          :class="`text-${resolveCampaignStatus(data.item.reviewStatus).variant}`"
          style="text-align: center"
        >
          {{ resolveCampaignStatus(data.item.status).text }}
        </div>
      </template>

      <!-- Column: ReviewStatus -->
      <template #cell(reviewStatus)="data">
        <div
          :class="`text-${resolveReviewStatus(data.item.reviewStatus).variant} text-nowrap`"
        >
          {{ resolveReviewStatus(data.item.reviewStatus).text }}
        </div>
      </template>

      <!-- Column: daily cost -->
      <template #cell(dailyCost)="data">
        <div
          class="text-nowrap"
        >
          {{ data.item.isParent ? data.value : '-' }}
        </div>
      </template>

      <!-- Column: Start Date -->
      <template #cell(start)="data">
        <div
          class="text-nowrap"
        >
          {{ data.item.isParent ? data.value : '-' }}
        </div>
      </template>

      <!-- Column: End Date -->
      <template #cell(end)="data">
        <div
          class="text-nowrap"
        >
          {{ data.item.isParent ? data.value : '-' }}
        </div>
      </template>

      <!-- Column: Created Date -->
      <template #cell(createdAt)="data">
        <div
          class="text-nowrap"
        >
          {{ data.item.isParent ? data.value : '-' }}
        </div>
      </template>
    </b-table>
    <!-- modal alert pause -->
    <b-modal
      id="modal-alert-pause"
      ok-title="일시 중지하기"
      cancel-title="취소"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      centered
      hide-header-close
      @ok="toggleIcon"
      @hide="selectedItem=null"
    >
      <b-card-text>
        현재 캠페인을 일시 중지하시겠습니까?
      </b-card-text>
    </b-modal>

    <!-- modal alert resume -->
    <b-modal
      id="modal-alert-resume"
      ok-title="집행하기"
      cancel-title="취소"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      centered
      hide-header-close
      @ok="toggleIcon"
      @hide="selectedItem=null"
    >
      <b-card-text>
        현재 캠페인을 계속 집행하시겠습니까?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BLink, BIcon, BModal, VBModal,
  BCardText,
} from 'bootstrap-vue'
import { computed, inject } from '@vue/composition-api'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useStatusResolver from '@/views/utils/useStatusResolver'
import useCampaignTask from '@/views/utils/task/useCampaignTask'
import useDate from '@/views/utils/useDate'
import useNumber from '@/views/utils/useNumber'
import useAdminTask from '@/views/utils/task/useAdminTask'
import { adminRouteName } from '@/router/routes/admin'

export default {
  components: {
    BTable,
    BLink,
    BIcon,
    BModal,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const { resolveCampaignStatus, resolveReviewStatus } = useStatusResolver()
    const { updateStatus } = useCampaignTask()
    const { parseDateTable } = useDate()
    const { toBigNumber, toPercent, toMoney } = useNumber()
    const { fetchCampaignList } = useAdminTask()
    const { t } = useI18nUtils()

    const setCurrentCampaign = inject('setCurrentCampaign')
    const showAccepted = inject('showAccepted')
    const items = inject('items')
    const currentPage = inject('currentPage')
    const sortBy = inject('sortBy')
    const isSortDirDesc = inject('isSortDirDesc')
    const expandIndexes = inject('expandIndexes')

    const tableColumns = computed(() => [
      { key: 'name', label: t('CampaignName') },
      { key: 'adAccount', label: '광고계정', formatter: val => val?.name ?? '-' },
      { key: 'channel', label: t('Channel') },
      { key: (showAccepted.value) ? 'status' : 'reviewStatus', label: t('Status') },
      {
        key: 'impression',
        label: t('Impressions'),
        sortable: true,
        formatter: toBigNumber,
      },
      {
        key: 'click',
        label: t('Clicks'),
        sortable: true,
        formatter: toBigNumber,
      },
      {
        key: 'clickRate',
        label: t('ClickRate'),
        sortable: true,
        formatter: toPercent,
      },
      { key: 'dailyCost', label: t('DailyCost'), formatter: toMoney },
      { key: 'value', label: t('CampaignValue'), formatter: toMoney },
      { key: 'start', label: t('openAt'), formatter: val => parseDateTable(val) },
      { key: 'end', label: t('closeAt'), formatter: val => parseDateTable(val) },
      { key: 'createdAt', label: t('EnrolledAt'), formatter: val => parseDateTable(val) },
    ])

    const toggleIcon = async item => {
      const newItem = { ...item }
      if (newItem.status === 'ACTIVATED') {
        newItem.status = 'PAUSED'
      } else {
        newItem.status = 'ACTIVATED'
      }
      await updateStatus(newItem)
      const campaigns = await fetchCampaignList()
      setCurrentCampaign(campaigns)
    }

    const moveTo = item => {
      router.push({ name: adminRouteName.campaignView, params: { campaignId: item.id } })
    }

    const isShowing = item => expandIndexes.value.findIndex(id => id === item.id) !== -1

    const toggleExpand = item => {
      const index = expandIndexes.value.findIndex(id => id === item.id)
      if (index === -1) {
        expandIndexes.value.push(item.id)
      } else {
        expandIndexes.value.splice(index, 1)
      }
    }

    return {
      moveTo,
      items,
      tableColumns,
      currentPage,
      sortBy,
      isSortDirDesc,
      resolveCampaignStatus,
      resolveReviewStatus,
      toggleIcon,
      isShowing,
      toggleExpand,
    }
  },
}
</script>
<style lang="scss">
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
